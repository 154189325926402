<template>
  <div class="bg-white">
    <div class="flex justify-between items-center px-6 bg-white mt-2 py-4">
      <h3 class="font-semibold uppercase text-oDark text-xl">
        Topup History
      </h3>
      <DateRangePicker class="mr-0"></DateRangePicker>
    </div>
    <div
      class="flex justify-between items-center flex-wrap md:w-full px-6 py-4 mb-4"
    >
      <div class="flex">
        <oto-search v-model="search" class="pr-2"></oto-search>
        <!-- <Filters class="flex-none mt-3 md:mt-0" />
        <FilterPills class="flex-1" /> -->
        <filter-dropdown :has-arrow="true">
          <template v-slot:button>
            <div
              class="text-left text-14px text-oCharcoal"
              style="min-width: 106px"
            >
              <span class="font-bold mx-2">Filter</span>
            </div>
          </template>
          <template v-slot:menu>
            <div class=" flex md:flex-wrap lg:flex-nowrap	 md:p-2">
              <filter-group name="Filter Name">
                <div
                  class="mb-2 whitespace-nowrap flex items-center text-gray-600 cursor-pointer"
                >
                  <input
                    type="checkbox"
                    class="mr-2 form-checkbox cursor-pointer text-oDark border-oDark transition duration-150 ease-in-out focus:shadow-none text-oDark border-oDark focus:border-oDark"
                  />
                  <span class="whitespace-nowrap" style="white-space: nowrap;">
                    OTORide-HQ
                  </span>
                </div>
              </filter-group>
              <filter-group name="Filter Name">
                <div
                  class="mb-2 whitespace-nowrap flex items-center text-gray-600 cursor-pointer"
                >
                  <input
                    type="checkbox"
                    class="mr-2 form-checkbox cursor-pointer text-gray-800 border-gray-800 transition duration-150 ease-in-out focus:shadow-none text-oDark border-oDark focus:border-oDark"
                  />
                  <span class="whitespace-nowrap" style="white-space: nowrap;">
                    OTORide-HQ
                  </span>
                </div>
              </filter-group>
            </div>
          </template>
        </filter-dropdown>
      </div>
      <ExportAsDropdown></ExportAsDropdown>
    </div>

    <div class="table w-full">
      <fsTableContainer class="vld-parent px-4">
        <loading :active.sync="isLoading" :is-full-page="false" />
        <fsTable class="w-full">
          <fsTableHead>
            <fsTableHeadItem
              v-for="(item, index) in tableHeaders"
              :key="`fs-table-header-${index}`"
              :item="item"
              @apply-sorting="handleSorting($event)"
            />
          </fsTableHead>
          <template v-for="(item, index) in indexData">
            <fsTableRow :key="`fs-table-row-${index}`">
              <fsTableRowItem>
                <router-link
                  :to="{
                    name: 'ViewTrip',
                    params: { id: item.id },
                  }"
                  class="font-bold text-sm text-blue-600"
                  >#{{ item.id | onlyLastFive }}</router-link
                >
              </fsTableRowItem>

              <fsTableRowItem>
                <router-link
                  :to="{
                    name: 'ViewRiderUserProfile',
                    params: { id: item.topup_to_user.id },
                  }"
                  class="font-bold text-sm text-blue-600"
                  >{{
                    item.topup_to_user ? item.topup_to_user.full_name : '--'
                  }}</router-link
                >
              </fsTableRowItem>

              <fsTableRowItem>
                <span class="text-sm text-gray-700 font-normal">{{
                  item.created_at | friendlyDateTime
                }}</span>
              </fsTableRowItem>

              <fsTableRowItem>
                <span class="font-bold text-md"
                  >{{ symbol }} {{ item.amount }}</span
                >
              </fsTableRowItem>

              <fsTableRowItem>
                <span
                  v-if="item.status == 'C'"
                  class="success bg-green-500 py-1 text-white px-4 rounded"
                  >Success</span
                >
                <span
                  v-if="item.status == 'P'"
                  class="error bg-red-500 py-1 text-white px-4 rounded"
                  >Pending</span
                >
              </fsTableRowItem>
              <fsTableRowItem>
                <div class="flex items-center">
                  <router-link to="#" class="text-gray-500 text-lg mx-2">
                    <i class="fas fa-eye"></i>
                  </router-link>
                  <router-link to="#" class="text-gray-500 text-lg mx-2">
                    <i class="fas fa-download    "></i>
                  </router-link>
                </div>
              </fsTableRowItem>
            </fsTableRow>
          </template>
        </fsTable>
        <fs-table-foot
          :total-items="indexMetaData.count.total"
          :pagination-summary="paginationSummary"
        />
      </fsTableContainer>
    </div>
  </div>
</template>

<script>
// import InfoGridColumn from "@/components/layout/InfoGridColumn.vue";
import DateRangePicker from '@/components/picker/date-range/DateRangePicker.vue'
import OtoSearch from '@/components/ui/OtoSearch.vue'
import FilterDropdown from '@/components/dropdown/filterDropdown/FilterDropdown.vue'
import FilterGroup from '@/components/dropdown/filterDropdown/FilterGroup.vue'

import isLoadingMixin from '@/mixins/isLoadingMixin'
import queryStringMixin from '@/mixins/queryStringMixin'
import queryStringSearchMixin from '@/mixins/queryStringSearchMixin'
import queryStringFiltersMixin from '@/mixins/queryStringFiltersMixin'
import getSubIndexDataMixin from '@/mixins/getSubIndexDataMixin'
import ExportAsDropdown from '@/components/dropdown/ExportAsDropdown.vue'
import fsTableUiMixin from '@/mixins/fsTableUiMixin'
import { AgentUserConfig } from '@/config/AgentUserConfig'
import { mapGetters } from 'vuex'
// import Filters from "./filter-components/Filters";
// import FilterPills from "./filter-components/FilterPills";
export default {
  components: {
    // InfoGridColumn,
    DateRangePicker,
    OtoSearch,
    FilterDropdown,
    FilterGroup,
    ExportAsDropdown,

    // Filters,
    // FilterPills,
  },
  mixins: [
    isLoadingMixin,
    queryStringMixin,
    queryStringSearchMixin,
    queryStringFiltersMixin,
    getSubIndexDataMixin,
    fsTableUiMixin,
  ],
  data: () => ({
    symbol: '$',
    open: false,
    hovered: false,
    activeGroupFilter: '',
    search: '',
    tableHeaders: [
      {
        name: 'Invoice ID',
        width: '10%',
        field: 'id',
        sort: null,
        order: '',
      },
      {
        name: 'Topup To',
        width: '10%',
        field: 'topup_user',
        sort: null,
        order: '',
      },
      {
        name: 'Topup Date & Time',
        width: '10%',
        field: 'created_at',
        sort: null,
        order: '',
      },
      {
        name: 'Topup Amount',
        width: '10%',
        field: 'amount',
        sort: null,
        order: '',
      },
      {
        name: 'Status',
        width: '10%',
        field: 'status',
        sort: null,
        order: '',
      },
      {
        name: 'Invoice',
        width: '10%',
        field: null,
        sort: null,
        order: '',
      },
    ],
    // indexData: [{}]
  }),
  computed: {
    ...mapGetters('auth', ['organizationInfo']),
    fleetFilterSummary() {
      return this.fleetFilterOptions.reduce((summary, option) => {
        if (option['is_checked']) {
          summary += (summary === '' ? '' : ', ') + option['name']
        }
        return summary
      }, '')
    },
    // agentId() {
    //   return "c194d53c-45b7-478a-983e-374078063aa7";
    // },
    indexDataEndpoint() {
      return AgentUserConfig.api.topupHistory(this.$route.params.id)
    },
  },
  mounted() {
    // this.indexDataEndpoint = AgentUserConfig.api.topupHistory(this.$route.params.id)
    this.symbol = this.organizationInfo.default_currency
      ? this.organizationInfo.default_currency.symbol
      : '$'
    console.log('parsed link : ', this.indexDataEndpoint, this.getQueryString)
    window.addEventListener(AgentUserConfig.events.refresh, () => {
      console.log('Reloading table with', this.getQueryString)
      this.getIndexData(this.getQueryString)
    })
  },
  watch: {
    indexData: {
      handler(data) {
        console.info({
          indexStatus: data,
        })
      },
    },
    deep: true,
  },
}
</script>

<style></style>
